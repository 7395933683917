import React, { useState } from "react";
import { CustomInput } from "components/UI/CustomInput";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useUser } from "context/userContext";

import axios from "axios";

import { useTranslation } from "react-i18next";

interface FormValues {
  email: string;
  confirmEmail: string;
  promo: string;
  instagram: string;
}

const UserAuth: React.FC<{
  finishHandler: Function;
  showInstagram?: boolean;
}> = ({ finishHandler, showInstagram = false }) => {
  const initialValues: FormValues = {
    email: "",
    confirmEmail: "",
    promo: "",
    instagram: "",
  };

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("field.invalidEmailError"))
      .required(t("field.requiredFieldError")),
    confirmEmail: Yup.string()
      .email(t("field.invalidEmailError"))
      .required(t("field.requiredFieldError"))
      .oneOf([Yup.ref("email")], t("field.emailDoesMatchError")),
    promo: Yup.string().email(t("field.invalidEmailError")),
    instagram: Yup.string().required(t("field.requiredFieldError")),
    // sex: Yup.string().e
  });

  const user = useUser();

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    user.updateUser("email", values.email);
    user.updateUser("promo", values.promo);
    user.updateUser("instagram", values.instagram);
    axios
      .post("/users", {
        email: values.email,
        promo: values.promo,
        instagram: values.instagram,
      })
      .then(() => {
        finishHandler();
        actions.setSubmitting(false);
      })
      .catch((e) => {
        let data = e?.response?.data;

        if (data?.code == "promo-not-found") {
          actions.setErrors({ promo: t("userContacts.errorPromoNotFound") });
        }
        if (data?.code == "promo-signined") {
          actions.setErrors({ promo: t("userContacts.errorSignedPromoEmail") });
        }
        if (data?.code == "test-passed") {
          actions.setErrors({ promo: t("userContacts.errorTestPassed") });
        }
      });
  };

  return (
    <>
      <div className="h-full flex flex-col justify-center">
        <div className="mt-8">
          <p className="text-2xl text-white text-center mb-6 font-bold">
            {t("userAuth.title")}
          </p>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <CustomInput
                      name="email"
                      containerClassName="mb-5"
                      label={t("userAuth.emailLabelInput")}
                      type="email"
                      placeholder="my@mail.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      errorMessage={
                        touched.email && errors.email ? errors.email : ""
                      }
                    />
                    <CustomInput
                      name="confirmEmail"
                      containerClassName="mb-5"
                      label={t("userAuth.confirmEmailLabelInput")}
                      type="email"
                      placeholder="my@mail.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmEmail}
                      errorMessage={
                        touched.confirmEmail && errors.confirmEmail
                          ? errors.confirmEmail
                          : ""
                      }
                    />
                    {showInstagram && (
                      <CustomInput
                        // containerClassName="mt-5"
                        name="instagram"
                        containerClassName="mb-5"
                        label="Instagram"
                        type="text"
                        placeholder="username"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.instagram}
                        errorMessage={
                          touched.instagram && errors.instagram ? errors.instagram : ""
                        }
                      />
                    )}

                    {!showInstagram && (
                      <CustomInput
                        containerClassName="mt-5"
                        name="promo"
                        label={t("userAuth.promoEmailLabelInput")}
                        type="email"
                        placeholder="my@mail.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.promo}
                        errorMessage={
                          touched.promo && errors.promo ? errors.promo : ""
                        }
                      />
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn mt-10 btn-large btn-block btn-primary w-full text-lg py-3 h-auto"
                  >
                    {t("button.next")}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAuth;
