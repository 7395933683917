import { useEffect, useState } from "react";
import { SettingsType, CodeBindType } from "../../../types/Settings.type";
import axios from "axios";
import { TestResultType } from "../../../types/TestResult.type";
import ResultCard from "./ResultCard";
import { toast } from "react-toastify";
import { ResultMaskType } from "../../../types/ResultMask.type";

let initialEnabledShowBind = false;

if (localStorage.getItem("enabledShowBind")) {
  initialEnabledShowBind = localStorage.getItem("enabledShowBind") == "true";
}

const Results = () => {
  const [settings, setSettings] = useState<SettingsType[]>([]);
  const [currentSettingId, setCurrentSettingId] = useState<string | null>(null);
  const [enabledShowBind, setEnabledShowBind] = useState(
    initialEnabledShowBind
  );
  const [results, setResults] = useState<TestResultType[]>([]);
  const [masks, setMasks] = useState<ResultMaskType[]>([]);
  const [percentCodeImportant, setPercentCodeImportant] = useState(
    localStorage.getItem("percent-code-important") || 50
  );

  useEffect(() => {
    axios.get("/settings").then(({ data }) => {
      setSettings(data);
      setCurrentSettingId(data[0]._id);
    });
    axios.get("/result-mask/all").then(({ data }) => {
      setMasks(data);
    });
  }, []);

  const currentSetting = settings.find(
    (setting) => setting._id == currentSettingId
  );

  const bindCodeList: CodeBindType[] = currentSetting?.codesBind || [];
  const currentMode = currentSetting?.mode;

  useEffect(() => {
    if (currentSettingId) {
      axios
        .get(`/test-result`, { params: { mode: currentMode } })
        .then(({ data }) => {
          setResults(data);
        });
    }
  }, [currentSettingId]);

  const deleteItemHandler = (id: string) => {
    if (window.confirm("Вы действительно хотите удалить результат?")) {
      axios.delete(`/test-result`, { data: { id: id } }).then(() => {
        setResults(results.filter((result) => result._id !== id));
        toast.success("Результат удален");
      });
    }
  };

  const changePercentCodeImportant = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let v = Number(event.target.value);
    setPercentCodeImportant(v);
    localStorage.setItem("percent-code-important", v + "");
  };

  const changeEnabledShowBind = (value: boolean) => {
    setEnabledShowBind(value);
    localStorage.setItem("enabledShowBind", value.toString());
  };

  const getBindCode = (
    (codeBind: CodeBindType[], enabledShowBind: boolean) => (code: string) => {
      if (!enabledShowBind) return code;
      return codeBind.find((bind) => bind.code == code)?.bind || code;
    }
  )(bindCodeList, enabledShowBind);

  const maskList = masks.filter((mask) => mask.mode == currentMode);

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <div>
          {settings.map((setting) => (
            <button
              key={setting._id}
              className={`${
                currentSettingId == setting._id ? "btn-primary" : "btn-outline"
              } btn btn-sm mr-2`}
              onClick={() => setCurrentSettingId(setting._id)}
            >
              {setting.mode}
            </button>
          ))}
        </div>
        <div className="flex">
          <div className="flex flex-col items-start space-y-4 mr-2">
            {/* Лейбл и значение */}
            <label className="text-sm">
              Процент значимости кода:
              <span className="text-accent">{percentCodeImportant} %</span>
            </label>

            {/* Ползунок */}
            <input
              type="range"
              min={50}
              max={80}
              value={percentCodeImportant}
              onChange={changePercentCodeImportant}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary"
            />
          </div>
          <label className="label cursor-pointer flex justify-start mb-2">
            <input
              type="checkbox"
              className="toggle toggle-primary"
              checked={enabledShowBind}
              onChange={() => changeEnabledShowBind(!enabledShowBind)}
            />
            <span className="label-text ml-2">Включить привязку кода</span>
          </label>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {results.map((result) => (
          <ResultCard
            codePercentImportant={+percentCodeImportant}
            getBindCode={getBindCode}
            key={result._id}
            result={result}
            deleteItemHandler={deleteItemHandler}
            maskList={maskList}
          />
        ))}
      </div>
    </div>
  );
};

export default Results;
