import { useTranslation } from "react-i18next";

import { useUser } from "context/userContext";
import { getDomain } from "utils/getDomain";
import { Icon } from "@iconify/react";

import i18n from "i18next";

const DOMAIN = getDomain();

const SelectSex = ({ finishHandler }: { finishHandler: () => void }) => {
  const { t } = useTranslation();
  const user = useUser();

  function changeLanguage(value: string) {
    i18n.changeLanguage(value);
  }

  const handleSex = (sex: string) => {
    user.updateUser("sex", sex);
    finishHandler();
  };

  return (
    <div className="h-full flex flex-col items-center justify-end pb-10">
      <p className="text-3xl text-center font-bold text-white mb-4">
        {t("bitchScale.selectSex.title")}
      </p>
      <div className="flex mt-6 items-center justify-center">
        <button
          onClick={() => {
            handleSex("male");
          }}
          className="btn btn-blue btn-shadow btn-outline btn-lg px-10 mx-2 text-xl"
        >
          {t("bitchScale.selectSex.boy")}
        </button>
        <button
          onClick={() => {
            handleSex("female");
          }}
          className="btn btn-pink btn-shadow btn-outline btn-lg px-10 mx-2 text-xl"
        >
          {t("bitchScale.selectSex.girl")}
        </button>
      </div>
      <div className="flex items-center justify-center mt-24">
        <div
          className="cursor-pointer"
          onClick={() => {
            changeLanguage("geo");
          }}
        >
          <Icon height={36} icon="flag:ge-4x3" />
        </div>
        <a
          href="https://www.instagram.com"
          target="_blank"
          className="mx-6 text-white cursor-pointer"
        >
          <Icon height={54} icon="mdi:instagram" />
        </a>
        <div
          className="cursor-pointer"
          onClick={() => {
            changeLanguage("eng");
          }}
        >
          <Icon height={36} icon="flag:gb-4x3" />
        </div>
      </div>
      {/* <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-900 via-purple-800 to-black"> */}
      {/* <button className="relative px-6 py-3 text-lg font-bold text-white rounded-lg bg-transparent backdrop-blur-md border border-blue-500 shadow-[] hover:shadow-[0_0_30px_rgba(0,255,255,0.7)] transition-shadow duration-300">
        Boy
      </button> */}

      {/* </div> */}
      {/* <p className="text-center mb-6 font-bold text-primary text-5xl">
        {DOMAIN.toUpperCase()}
      </p> */}
      {/* <p className="text-2xl text-center mb-6 font-bold">
        {t("userSex.title")}
      </p>

      <div className="">
        <button
          className="btn btn-primary btn-outline flex flex-col btn-block  my-2 h-auto btn-lg text-base py-3"
          onClick={() => handleSex("male")}
        >
          Boy
        </button>
        <button
          className="btn btn-primary btn-outline flex flex-col btn-block  my-2 h-auto btn-lg text-base py-3"
          onClick={() => handleSex("female")}
        >
          Girl
        </button> */}
      {/* </div> */}
    </div>
  );
};

export default SelectSex;
