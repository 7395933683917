import { CodeItemType } from "../../../types/TestResult.type";
import { ResultMaskType } from "../../../types/ResultMask.type";

interface ICodeRatingObj {
  [k: string]: number;
}

interface ICodeWithInfo {
  code: string;
  weight: number;
  name: string;
  color: string;
  percent?: number;
  rating: number;
}

export default function calculateResult(
  codes: CodeItemType[],
  mask: ResultMaskType,
  codePercentImportant: number,
  getBindCode: (code: string) => string
) {
  //   console.log(codes, "codes");
  const codesRatingObject = getCodesRatingObject(codes);

  const codeWithWeightListInfo = getCodeListWithInfo(codesRatingObject);

  console.log(codeWithWeightListInfo, "codeWithWeightListInfo");

  const allSumRating = +codeWithWeightListInfo
    .reduce((acc, item) => acc + item.rating, 0)
    .toFixed(2);

  let codeWithWeightAndPercentList = codeWithWeightListInfo.map((c) => {
    return {
      ...c,
      percent: +(c.rating / allSumRating).toFixed(3),
    };
  });
  codeWithWeightAndPercentList.sort((a, b) => b.percent - a.percent);

  console.log(codeWithWeightAndPercentList, "codeWithWeightAndPercentList");

  let mergeCodeResult = getMergeCodeResult(
    codes,
    codePercentImportant,
    getBindCode,
    codeWithWeightAndPercentList
  );

  // порядок сортировки кодов
  const sortModeCode = mask.sortModeCode;

  const codeListPolarity = getCodeWeightWithPolarity(
    sortModeCode,
    codeWithWeightListInfo
  );

  let resultSumPolarity = codeListPolarity.reduce(
    (acc, item) => acc + item.weight,
    0
  );

  let grades = getGradeListWithValue(mask.gradeList.map(g => g.rus  ), resultSumPolarity);

  return {
    name: mask.name,
    grades,
    weights: codeListPolarity,
    resultSum: resultSumPolarity,
    mergeCodeResult,
    fullRatingValue: allSumRating,
  };
}

const getMergeCodeResult = (
  codes: CodeItemType[],
  importantPercent: number,
  getBindCode: (code: string) => string,
  codeWithInfoList: ICodeWithInfo[]
) => {
  interface IResult extends CodeItemType {
    ratingPercent: number;
    statusName: string;
    statusColor: string;
    bindCode: string;
    isImportant: boolean;
  }

  let result: IResult[] = [];

  //   let accumRatingPercent = 0;

  //   let skipSetImportant = false;
  codeWithInfoList.forEach((c) => {
    let bindCode = getBindCode(c.code);
    let codeItem = codes.find((ci) => ci.code == c.code)!;

    let ratingPercent = +(c.percent! * 100).toFixed(2);

    result.push({
      ratingPercent,
      statusName: c.name,
      statusColor: c.color,
      bindCode,
      isImportant: false,
      ...codeItem,
    });
  });

  let accum = 0;
  result = result.map((i) => {
    // let curr = accum +
    let isImportant = accum >= importantPercent;

    accum += i.ratingPercent;

    return {
      ...i,
      isImportant: !isImportant,
    };
  });

  console.log(result, "result");
  return result;
};

const getGradeListWithValue = (grades: string[], targetValue: number) => {
  // Функция для определения цвета
  const midIndex = Math.floor(grades.length / 2);

  function getColor(value: number, index: number, midIndex: number) {
    if (value === 0) {
      return "#FFFF00"; // Жёлтый
    }
    const distanceFromMid = Math.abs(index - midIndex);
    if (value > 0) {
      return distanceFromMid <= 4 ? "#90EE90" : "#006400"; // Светло-зелёный или тёмно-зелёный
    } else {
      return distanceFromMid <= 4 ? "#FF4500" : "#8B0000"; // Ярко-красный или тёмно-красный
    }
  }

  // Создать массив объектов с полями value, name и color
  const modifiedArr = grades.map((name, index) => {
    const step = 2; // Шаг увеличения/уменьшения
    let value;
    if (index === midIndex) {
      value = 0; // Средний элемент равен 0
    } else if (index < midIndex) {
      value = (midIndex - index) * step; // Слева от середины
    } else {
      value = -(index - midIndex) * step; // Справа от середины
    }
    const color = getColor(value, index, midIndex); // Определяем цвет
    return { value, name, color };
  });

  function addMatchFlagWithinRange(
    arr: { value: number; name: string; color: string }[],
    targetValue: number
  ) {
    // [3,2,1, 0 ,-1,-2,-3,-4]
    let isPositiveTargetValue = targetValue >= 0;

    // let elem;

    // по возрастанию
    let positiveArr = arr
      .filter((a) => a.value >= 0)
      .sort((a, b) => a.value - b.value);
    // по убыванию
    let negativeArr = arr
      .filter((a) => a.value <= 0)
      .sort((a, b) => b.value - a.value);

    let aroundList = isPositiveTargetValue ? positiveArr : negativeArr;

    let findedItem: { value: number; name: string; color: string } | undefined;

    // console.log(aroundList, "aroundList");

    aroundList.forEach((e, i) => {
      if (findedItem) return;

      let itemValue = e.value;
      let nextItem = aroundList[i + 1];

      if (!nextItem) {
        findedItem = e;
        return;
      }

      let itemValueAbs = Math.abs(itemValue);
      let nextItemValueAbs = Math.abs(nextItem.value);
      let targetValueAbs = Math.abs(targetValue);

      // текущий элемент меньше целевого значения
      if (itemValueAbs <= targetValueAbs) {
        // следующий элемент больше целевого значения
        // пропускаем

        if (nextItemValueAbs < targetValueAbs) {
          return;
        }
        // нашли - ищем что ближе к целевому значению

        let nextDiff = Math.abs(nextItemValueAbs - targetValueAbs);
        let curDiff = Math.abs(itemValueAbs - targetValueAbs);

        findedItem = nextDiff < curDiff ? nextItem : e;
      }
    });

    // console.log(findedItem, "findedItem");

    return findedItem?.value;
  }

  let val = addMatchFlagWithinRange(modifiedArr, targetValue);

  let modifiedArrWithFlag = modifiedArr.map((item) => {
    if (item.value === val) {
      return { ...item, flag: true };
    }
    return item;
  });

  return modifiedArrWithFlag;
};

const getCodeWeightWithPolarity = (
  sortModeCode: string[],
  codeInfoList: ICodeWithInfo[]
) => {
  const sortModeCodeExistMiddle = sortModeCode.length % 2 > 0;

  let leftPartWeight: { code: string; weight: number }[] = sortModeCode
    .slice(0, Math.floor(sortModeCode.length / 2))
    .map((code) => {
      let weight = codeInfoList.find((item) => item.code === code)?.weight!;
      if (weight === undefined) return;
      return {
        code,
        weight,
      };
    })
    .filter((item) => item) as { code: string; weight: number }[];

  let rightPartWeight = sortModeCode
    .slice(Math.ceil(sortModeCode.length / 2))
    .map((code) => {
      let weight = codeInfoList.find((item) => item.code === code)?.weight!;
      if (!weight) return;
      return {
        code,
        weight: -weight,
      };
    })
    .filter((item) => item) as { code: string; weight: number }[];

  let middlePartWeight: { code: string; weight: number }[] = [];
  if (sortModeCodeExistMiddle) {
    let middleIndex = Math.floor(sortModeCode.length / 2);
    let code = sortModeCode[middleIndex];
    middlePartWeight = [
      {
        code,
        weight: 0,
      },
    ];
  }

  let weights: { code: string; weight: number }[] = [
    ...leftPartWeight,
    ...middlePartWeight,
    ...rightPartWeight,
  ];

  return weights;
};

const getCodesRatingObject = (codes: CodeItemType[]): ICodeRatingObj => {
  // создаем объект код - рейтинг
  const codesRatingObject = Object.fromEntries(
    codes.map((code) => [code.code, 0])
  );
  codes.forEach((codeItem) => {
    let rating = 0;

    // rating += codeItem.value / 75; // TODO 135 весы изменить

    rating += codeItem.value / 135;

    rating += codeItem.crucial / 4;

    rating += codeItem.count / 8;

    console.log(codeItem.code, rating);

    codesRatingObject[codeItem.code] = +rating.toFixed(3);
  });
  return codesRatingObject;
};

const getCodeListWithInfo = (
  codesRatingObject: ICodeRatingObj
): ICodeWithInfo[] => {
  return Object.entries(codesRatingObject).map(([code, rating]) => {
    // let weightResult = 0;
    // let colorResult = "";
    // let nameResult = "";
    // let ratingResult = 0;

    // for (let i = 0; i < ratingToWeight.length; i++) {
    //   const item = ratingToWeight[i];

    //   let nextWeight = ratingToWeight[i + 1];

    //   if (!nextWeight) {
    //     weightResult = item.weight;
    //     colorResult = item.color;
    //     nameResult = item.name;
    //     ratingResult = rating;
    //     break;
    //   }

    //   if (rating >= item.more && rating < nextWeight.more) {
    //     weightResult = item.weight;
    //     colorResult = item.color;
    //     nameResult = item.name;
    //     ratingResult = rating;
    //     break;
    //   }
    // }

    // return {
    //   code,
    //   weight: weightResult,
    //   name: nameResult,
    //   color: colorResult,
    //   rating: ratingResult,
    // };

    let resultItem;

    for (let i = 0; i < ratingToWeight.length; i++) {
      const currentItem = ratingToWeight[i];
      const nextItem = ratingToWeight[i + 1];
      resultItem = currentItem;
      console.log(currentItem, "currentItem");

      if (!nextItem) {
        resultItem = currentItem;
        break;
      }

      if (rating >= currentItem.more && rating < nextItem.more) {
        resultItem = currentItem;
        break;
      } else {
        continue;
      }
    }

    if (!resultItem) {
      throw new Error("Result item not found!");
    }

    return {
      code,
      weight: resultItem.weight,
      name: resultItem.name,
      color: resultItem.color,
      rating: rating,
    };
  });
};

// const ratingToWeight = [
//   {
//     more: 0,
//     weight: 0.45,
//   },
//   {
//     more: 0.438,
//     weight: 0.97,
//   },
//   {
//     more: 0.875,
//     weight: 1.89,
//   },
//   {
//     more: 1.312,
//     weight: 3.29,
//   },
//   {
//     more: 1.75,
//     weight: 5.13,
//   },
//   {
//     more: 2.188,
//     weight: 7.17,
//   },
//   {
//     more: 2.625,
//     weight: 8.95,
//   },
//   {
//     more: 3.062,
//     weight: 10,
//   },
// ];
const ratingToWeight = [
  {
    more: 0,
    weight: 0.45,
    name: "очень слабый",
    color: "",
  },
  {
    more: 0.375,
    weight: 0.97,
    name: "слабый",
    color: "",
  },
  {
    more: 0.75,
    weight: 1.89,
    name: "средний",
    color: "#ffc824",
  },
  {
    more: 1.125,
    weight: 3.29,
    name: "выше среднего",
    color: "#24ff3d",
  },
  {
    more: 1.5,
    weight: 5.13,
    name: "сильный",
    color: "#30a63e",
  },
  {
    more: 1.875,
    weight: 7.17,
    name: "очень сильный",
    color: "#63edf7",
  },
  {
    more: 2.25,
    weight: 8.95,
    name: "подавляющий",
    color: "#6868fc",
  },
  {
    more: 2.625,
    weight: 10,
    name: "доминация",
    color: "#2727f5",
  },
];
