import { useEffect, useState } from "react";
import { useStep } from "../hooks";

import { motion, AnimatePresence } from "framer-motion";

import { useTranslation } from "react-i18next";
// assets
import backImg from "assets/bs-back.jpg";
import backImg2 from "assets/bs-back2.jpg";

import Questions from "./Questions/index";
import SelectSex from "./SelectSex";
import Location from "./Location";
import Contacts from "./Contacts";
import Finish from "./Finish";

import { useUser } from "context/userContext";

import axios from "axios";

// import Header from "../../layouts/Header";

type BitchScaleStepType =
  | "user-gender"
  | "user-auth"
  | "questions"
  | "location"
  | "finish";

const bitchScaleSteps: BitchScaleStepType[] = [
  "user-gender",
  "user-auth",
  "questions",
  "location",
  "finish",
];

const bodyStyle = document.body.style;

const changeSeconds = 2;

const BitchScale = () => {
  const [showUi, setShowUi] = useState(false);
  const { step, nextHandler } = useStep(bitchScaleSteps);

  const t = useTranslation();
  const lang = t.i18n.language;

  const user = useUser();

  useEffect(() => {
    // setTimeout(() => {
    //   bodyStyle.animation = `moveBackground ${changeSeconds}s ease-in-out forwards`;
    //   setTimeout(() => {
    //     setShowUi(true);
    //   }, changeSeconds * 1000);
    // }, 2000);

    //   const fromPx = 0;

    bodyStyle.backgroundColor = "#000003";
    bodyStyle.backgroundImage = `url("${backImg}")`;
    bodyStyle.backgroundSize = "cover";
    bodyStyle.backgroundPosition = `center -140px`;
    bodyStyle.backgroundRepeat = "no-repeat";
    bodyStyle.height = "100vh";
    bodyStyle.transition = "background-image 1s ease-in";

    return () => {
      // Очистка стилей при размонтировании компонента
      bodyStyle.backgroundSize = "";
      bodyStyle.backgroundImage = "";
      bodyStyle.backgroundPosition = "";
      bodyStyle.backgroundRepeat = "";
      bodyStyle.height = "";
    };
  }, []);

  //   useEffect(() => {
  //     if (step == "user-auth") {
  //       bodyStyle.backgroundPosition = `center 0px`;
  //       bodyStyle.backgroundImage = `url("${backImg2}")`;
  //     }
  //   }, [step]);

  useEffect(() => {
    const bodyStyle = document.body.style;

    if (step === "user-auth") {
      // Удаляем анимацию, если step = "user-auth"
      bodyStyle.animation = "none";
      // Устанавливаем новое изображение и делаем его плавным
      bodyStyle.backgroundPosition = `center 0px`;
      bodyStyle.backgroundImage = `url("${backImg2}")`;
    }
    if (step == "location") {
      axios
        .post("/users/finish", Object.assign({ ...user }, { isFinish: false }))
        .then(() => {});
    }
    if (step == "finish") {
      axios
      .post("/users/finish", Object.assign({ ...user }, { isFinish: true }))
      .then(() => {});
    }
    // let resultTest = Object.assign({}, user, values);
    // console.log(resultTest, "resultTest");

    // ;
  }, [step]);

  const view = () => {
    return (
      <div className={`block h-full w-full bitch-page lang-${lang}`}>
        <AnimatePresence mode="wait">
          {step == "user-gender" && (
            <AnimationWrapper key="user-gender">
              <SelectSex finishHandler={nextHandler} />
            </AnimationWrapper>
          )}
          {step == "user-auth" && (
            <AnimationWrapper key="user-auth">
              <Contacts showInstagram={true} finishHandler={nextHandler} />
            </AnimationWrapper>
          )}
          {step == "questions" && (
            <AnimationWrapper key="questions">
              <Questions finishHandler={nextHandler} />
            </AnimationWrapper>
          )}
          {step == "location" && (
            <AnimationWrapper key="location">
              <Location finishHandler={nextHandler} />
            </AnimationWrapper>
          )}
          {step == "finish" && (
            <AnimationWrapper key="finish">
              <Finish />
            </AnimationWrapper>
          )}
          {/* </div>
          </div> */}
        </AnimatePresence>
      </div>
    );
  };

  return <>{view()}</>;
};

function AnimationWrapper({
  children,
  key,
}: {
  key: string;
  children: React.ReactNode;
}) {
  return (
    <ContainerWrap>
      <motion.div
        key={key}
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 0 }}
        transition={{ duration: 0.3 }}
        className="h-full"
      >
        {children}
      </motion.div>
    </ContainerWrap>
  );
}

function ContainerWrap({ children }: { children: React.ReactNode }) {
  return (
    <div className="h-full w-full flex justify-center ">
      <div className="w-10/12 sm:w-9/12 md:w-7/12 lg:w-5/12 xl:w-3/12">
        {children}
      </div>
    </div>
  );
}

export default BitchScale;
