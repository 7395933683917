import React, { useState, useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";

import { ResultMaskType } from "../../../types/ResultMask.type";
import axios from "axios";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

type LangType = "rus" | "geo" | "eng";
const langs: LangType[] = ["rus", "geo", "eng"];

type ResultMaskProps = {
  mode: string;
};

const ResultMask = ({ mode }: ResultMaskProps) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [masks, setMasks] = useState<ResultMaskType[]>([]);

  const lang = "rus";

  const fetchMasks = () => {
    axios
      .get("/result-mask", { params: { mode } })
      .then(({ data }: { data: ResultMaskType[] }) => {
        setMasks(data);
      });
  };

  const addMask = () => {
    setSubmitLoading(true);
    axios
      .post("/result-mask", { mode })
      .then(({ data }: { data: ResultMaskType }) => {
        setMasks([data, ...masks]);
        toast.success("Маска добавлена");
      })
      .finally(() => setSubmitLoading(false));
  };

  const updateMask = (mask: ResultMaskType) => {
    setMasks(masks.map((m) => (m._id === mask._id ? mask : m)));
  };

  const deleteMask = (id: string) => {
    if (window.confirm("Вы действительно хотите удалить маску?")) {
      axios.delete(`/result-mask/${id}`).then(() => {
        setMasks(masks.filter((m) => m._id !== id));
        toast.success("Маска удалена");
      });
    }
  };

  useEffect(fetchMasks, [mode]);

  return (
    <div className="mt-8">
      <div className="flex flex-col gap-4">
        <div className="collapse collapse-arrow border border-gray-200">
          <input type="checkbox" />
          <div className="collapse-title text-xl  font-medium">Маски</div>
          <div className="collapse-content">
            <div className="flex justify-end">
              <button
                className="btn btn-primary"
                onClick={addMask}
                disabled={submitLoading}
              >
                Добавить маску
              </button>
            </div>
            <div className="flex flex-col gap-2 mt-2">
              {masks.map((mask) => (
                <ResultMaskItem
                  deleteMask={deleteMask}
                  key={mask._id}
                  initialMask={mask}
                  updateMask={updateMask}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function ResultMaskItem({
  initialMask,
  deleteMask,
  updateMask,
}: {
  initialMask: ResultMaskType;
  deleteMask: (id: string) => void;
  updateMask: (mask: ResultMaskType) => void;
}) {
  const [mask, setMask] = useState<ResultMaskType>(initialMask);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [lang, setLang] = useState<LangType>("eng");

  let language: "rus" | "geo" | "eng" = "rus";
  if (mask.isBitchScale) {
    language = lang;
  }

  const saveHandler = () => {
    setIsSubmitted(true);
    axios
      .put(`/result-mask`, mask)
      .then(() => {
        toast.success("Маска сохранена");
      })
      .finally(() => setIsSubmitted(false));
  };

  const changeBitchScale = (e: any) => {
    setIsSubmitted(true);
    axios
      .put(`/result-mask/bitchscale`, {
        id: mask._id,
        isBitchScale: e.target.checked,
      })
      .then(({ data }) => {
        toast.success("Маска измененна");
        updateMask(data);
        setMask(data);
      })
      .catch((e) => {
        console.error({ e });
        toast.error(e?.response?.data?.message);
      })
      .finally(() => setIsSubmitted(false));
  };

  const changeMaskSex = (sex: string) => {
    setMask((prev) => Object.assign({}, mask, { sex }));
  };

  const changeGradeListItem = (
    index: number,
    value: string,
    language: LangType
  ) => {
    setMask(prev => {
      let gradeList = [...prev.gradeList];
      gradeList[index] = {
        ...gradeList[index],
        [language]: value,
      };
      return {
        ...prev,
        gradeList
      }
    })
    // console.log(value, language);
    // const gradeList = [...mask.gradeList];
    // // let newGradeList=  gradeList.map(g => {
    // //   return Object.assign({}, g, {[language]: value})
    // // })
    // gradeList[index] = {
    //   ...gradeList[index],
    //   [language]: value,
    // };
    // setMask({ ...mask, gradeList });
  };

  console.log({ language }, "lang");

  return (
    <div className="collapse collapse-arrow border border-gray-200">
      <input type="checkbox" />

      <div className="collapse-title text-lg  font-medium flex items-center">
        <p>{mask.name}</p>
      </div>

      <div className="collapse-content">
        <div className="flex-1">
          <div className="flex items-center">
            <div className="label">
              <span className="label-text">Название</span>
            </div>

            {/* */}
          </div>

          <input
            type="text"
            value={mask.name}
            onChange={(e) => setMask({ ...mask, name: e.target.value })}
            className="input w-full input-sm  input-bordered"
          />
        </div>
        <div className="flex items-center justify-between mt-2">
          <label className="label cursor-pointer flex justify-start ml-2">
            <input
              type="checkbox"
              className="toggle toggle-sm toggle-primary"
              checked={mask.isBitchScale}
              onChange={changeBitchScale}
            />
            <span className="label-text ml-2">BitchScale</span>
          </label>
          {mask.isBitchScale && (
            <div className="flex items-center">
              <div className="mr-2">
                <select
                  className="select select-sm select-primary "
                  value={lang}
                  onChange={(e) => setLang(e.target.value as LangType)}
                >
                  {langs.map((lang) => (
                    <option key={lang} value={lang}>
                      {lang.toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              <div className="">
                <select
                  className="select select-sm select-primary "
                  value={mask.sex}
                  onChange={(e) => changeMaskSex(e.target.value)}
                >
                  {[
                    { name: "-", value: "" },
                    { name: "МУЖ", value: "male" },
                    { name: "ЖЕН", value: "female" },
                  ].map((sex) => (
                    <option key={sex.value} value={sex.value}>
                      {sex.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>

        <div className="grid  grid-cols-1 sm:grid-cols-3 gap-2 mt-4">
          <div className="col-span-1">
            <p className="text-md mb-2">Порядок</p>
            <ResultMaskSortItems
              items={mask.sortModeCode}
              setItems={(items: string[]) =>
                setMask({ ...mask, sortModeCode: items })
              }
            />
          </div>
          <div className="col-span-2">
            <div className="">
              <p className="text-md mb-2">Оценки</p>
              <ResultMaskGradeList
                lang={language}
                gradeList={mask.gradeList}
                changeGradeListItem={changeGradeListItem}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between mt-4">
          <button
            onClick={() => deleteMask(mask._id)}
            className="btn btn-sm btn-error ml-3 btn-square btn-sm btn-outline"
          >
            <Icon fontSize="26px" icon="material-symbols:delete-outline" />
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={saveHandler}
            disabled={isSubmitted}
          >
            {isSubmitted ? "Сохранение..." : "Сохранить"}
          </button>
        </div>
      </div>
    </div>
  );
}

type ResultMaskSortItemsProps = {
  items: string[];
  setItems: (items: string[]) => void;
};

const ResultMaskGradeList = ({
  gradeList,
  changeGradeListItem,
  lang,
}: {
  lang: LangType;
  gradeList: {
    rus: string;
    geo: string;
    eng: string;
  }[];
  changeGradeListItem: (index: number, value: string, lang: LangType) => void;
}) => {
  return (
    <div className="flex flex-col gap-2 ">
      {gradeList.map((g, index) => {
        return (
          <input
            type="text"
            key={index + lang}
            value={g[lang]}
            onChange={(e) => changeGradeListItem(index, e.target.value, lang)}
            className="input w-full input-sm  input-bordered"
          />
        );
      })}
    </div>
  );
};

const ResultMaskSortItems: React.FC<ResultMaskSortItemsProps> = ({
  items,
  setItems,
}) => {
  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
    // Здесь можно добавить вызов API для сохранения нового порядка
  };
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="sortModeCodes">
        {(provided: any) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}

            // style={styles.droppable}
          >
            {items.map((code, index) => (
              <Draggable key={code} draggableId={code} index={index}>
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`p-2 border border-gray-200 rounded-md mb-2 ${
                      snapshot.isDragging ? "bg-primary" : ""
                    }`}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                  >
                    {code}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const styles = {
  droppable: {
    background: "#f8f8f8",
    padding: "16px",
    borderRadius: "4px",
    width: "300px",
  },
  item: {
    userSelect: "none",
    padding: "8px",
    margin: "0 0 8px 0",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
};

export default ResultMask;
