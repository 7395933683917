import Main from "./Main";
import BitchScale from "./BitchScale/index";
import { getDomain } from "../../utils/getDomain";

const domain = getDomain();

// console.log(domain, "domain");

const isBitchScale = domain === "bitchscale";

export default function MainPage({}) {
  return <>{isBitchScale ? <BitchScale /> : <Main />}</>;
}
