import { useState, useEffect } from "react";

import {
  QuestionInterface,
  TraitMoodInterface,
  TraitInterface,
  LangType,
  QuestionKey,
} from "./types";

import formattedMapTrait from "./formattedMapTrait";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { MoodQuestion } from "./MoodQuestion";

import { useUser } from "context/userContext";

const Questions: React.FC<{
  finishHandler: Function;
}> = ({ finishHandler: pageFinishHandler }) => {
  const [question, setQuestion] = useState<QuestionInterface | null>();
  const [negativeTraits, setNegativeTraits] = useState<TraitMoodInterface[]>(
    []
  );
  const [negativePercent, setNegativePercent] = useState<number>(0);
  const [positivePercent, setPositivePercent] = useState<number>(0);

  const [positiveTraits, setPositiveTraits] = useState<TraitMoodInterface[]>(
    []
  );
  const [currentCategory, setCurrentCategory] = useState<
    "negative" | "positive"
  >("negative");

  const user = useUser();

  useEffect(() => {
    axios.get("/question").then(({ data }) => {
      setQuestion(data);
    });
    axios
      .get("/traits/client", {})
      .then(({ data }: { data: TraitInterface[] }) => {
        let negativeTraits = data
          .filter((t) => t.mood === "negative")
          .map(formattedMapTrait());
        let positiveTraits = data
          .filter((t) => t.mood === "positive")
          .map(formattedMapTrait());
        setNegativeTraits(negativeTraits);
        setPositiveTraits(positiveTraits);
      });
  }, []);

  const finishHandler = (
    positiveTraits: TraitMoodInterface[],
    negativeTraits: TraitMoodInterface[]
  ) => {
    let allTraits = [...positiveTraits, ...negativeTraits];

    allTraits.sort((a, b) => (a.score > b.score ? -1 : 1));

    let result: { _id: string; value: number }[] = allTraits.map((t) => {
      return {
        _id: t._id,
        value: t.score,
      };
    });

    user.updateUser("test", result);
    pageFinishHandler();
  };

  const percent = Math.floor((negativePercent + positivePercent) / 2);

  const { t, i18n } = useTranslation();

  let lang = i18n.language as LangType;

  if (!question) return null;

  const positiveKey: QuestionKey =
    `positive${lang.toUpperCase()}` as QuestionKey;
  const negativeKey: QuestionKey =
    `negative${lang.toUpperCase()}` as QuestionKey;

  const positiveQuestion = question[positiveKey];
  const negativeQuestion = question[negativeKey];

  return (
    <>
      {/* <style>
        {`
          .custom-progress::-webkit-progress-value {
            border-radius: 0;
          }
          .custom-progress::-moz-progress-bar {
            border-radius: 0; 
          }
        `}
      </style> */}
      {/* <div className="fixed bottom-0 left-0 right-0 h-9">
        <progress
          className=" progress border-b-0 progress-primary rounded-none h-full border-solid border bg-white border-base-content custom-progress"
          value={percent}
          max="100"
        ></progress>
        <span className="absolute inset-0 flex items-center justify-center font-semibold text-base-content">
          {`${percent}%`}
        </span>
      </div> */}
      <div className="h-full flex-col flex justify-between gap-3 pt-8 pb-6">
        <p className="text-2xl text-white font-bold  text-center">
          {currentCategory == "negative" ? negativeQuestion : positiveQuestion}
        </p>
        <div className="flex-1 overflow-y-auto">
          {negativeTraits.length && currentCategory == "negative" && (
            <MoodQuestion
              lang={lang}
              setPercent={setNegativePercent}
              question={negativeQuestion}
              initTairs={negativeTraits}
              setFinish={(traits: TraitMoodInterface[]) => {
                setNegativeTraits(traits);
                setCurrentCategory("positive");
              }}
            />
          )}
          {negativeTraits.length && currentCategory == "positive" && (
            <MoodQuestion
              lang={lang}
              setPercent={setPositivePercent}
              question={positiveQuestion}
              initTairs={positiveTraits}
              setFinish={(traits: TraitMoodInterface[]) => {
                setPositiveTraits(traits);
                finishHandler(traits, negativeTraits);
              }}
            />
          )}
        </div>

        <div className="progress-bar">
          <div
            className="progress-bar-hidden"
            style={{
              width: `${100 - percent}%`,
            }}
          ></div>
          <div className="progress-bar-value">{percent}%</div>
        </div>
      </div>
    </>
  );
};

export default Questions;
