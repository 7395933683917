export default function getTextAndSubtext(text: string) {
  // Получаем текст вне скобок
  console.log(text, "in text");

  let outsideText = text.replace(/\(.*?\)/g, "").trim();

  // Получаем текст внутри скобок
  let insideText: any = text.match(/\((.*?)\)/);

  if (insideText) {
    insideText = insideText.map((item: any) =>
      item.replace(/[()]/g, "").trim()
    );
    insideText = insideText[0];
    // console.log("Тексты внутри скобок:", insideText);
  }

  return {
    text: outsideText,
    subtext: insideText || "",
  };
}
