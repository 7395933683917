import React, { createContext, useContext, useState, ReactNode } from "react";
import {uid} from 'uid'

interface UserContextType {
  email: string;
  promo: string;
  status: string;
  test: { _id: string; code: string; value: number }[];
  name: string;
  phone: string;
  country: string;
  city: string;
  sex: string;
  instagram: string;
  id: string;
}

const UserContext = createContext<any>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userData, setUserData] = useState<UserContextType>({
    email: "",
    promo: "",
    test: [],
    status: "",
    name: "",
    phone: "",
    country: "",
    city: "",
    sex: "",
    instagram: "",
    id: uid(32)
  });

  const updateUser = (name: string, value: any) => {
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  let value = {
    ...userData,
    updateUser,
  };

  console.log({ userData }, 'userData');

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
