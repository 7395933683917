import { useState, useCallback } from "react";

export const useStep = (steps: string[]) => {
  const [step, setStep] = useState<string>(steps[0]);

  const nextHandler = useCallback(() => {
    let stepIndex = steps.findIndex((s) => step === s);
    let nextStepIndex = stepIndex + 1;
    if (steps[nextStepIndex]) {
      setStep(steps[nextStepIndex]);
    }
  }, [step]);

  return { step, nextHandler };
};
