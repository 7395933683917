import { useEffect } from "react";
import { useStep } from "./hooks";

import Questions from "./Questions/index";
import CurrentStatus from "./CurrentStatus";
import UserContacts from "./UserContacts";
import Location from "./Location";
import UserAuth from "./UserAuth";
import Finish from "./Finish";

import { useUser } from "context/userContext";

import axios from "axios";
// import Header from "../../layouts/Header";

type MainStepType =
  | "current-status"
  | "user-auth"
  | "user-contacts"
  | "questions"
  | "location"
  | "finish";

const mainSteps: MainStepType[] = [
  "current-status",
  "user-auth",
  "user-contacts",
  "questions",
  "location",
  "finish",
];

const Main = () => {
  const { step, nextHandler } = useStep(mainSteps);

   const user = useUser();

  useEffect(() => {

    if (step == "location") {
      axios
        .post("/users/finish", Object.assign({ ...user }, { isFinish: false }))
        .then(() => {});
    }
    if (step == "finish") {
      axios
      .post("/users/finish", Object.assign({ ...user }, { isFinish: true }))
      .then(() => {});
    }
  }, [step]);


  return (
    <div className="p-3 pt-2 flex flex-col items-center">
      <div className="w-full sm:w-80 px-2 py-2 overflow-hidden mt-4">
        {/* <Header /> */}
        {step == "current-status" && (
          <CurrentStatus finishHandler={nextHandler} />
        )}
        {step == "user-auth" && <UserAuth finishHandler={nextHandler} />}
        {step == "user-contacts" && (
          <UserContacts finishHandler={nextHandler} />
        )}
        {step == "questions" && <Questions finishHandler={nextHandler} />}
        {step == "location" && <Location finishHandler={nextHandler} />}
        {step == "finish" && <Finish />}
      </div>
    </div>
  );
};

export default Main;
