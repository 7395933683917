import { TestResultType, CodeItemType } from "../../../types/TestResult.type";
import { ResultMaskType } from "../../../types/ResultMask.type";
import { Icon } from "@iconify/react";

import moment from "moment";

import ResultCardMasks from "./ResultCardMasks";
import getCalcualteResult from "./calcualteResult";

type ResultMaskItemType = {
  name: string;
  grades: any;
  weights: any;
  resultSum: number;
};

const ResultCard = ({
  result,
  deleteItemHandler,
  maskList,
  getBindCode,
  codePercentImportant,
}: {
  result: TestResultType;
  deleteItemHandler: (id: string) => void;
  getBindCode: (code: string) => string;
  maskList: ResultMaskType[];
  codePercentImportant: number;
}) => {
  const maskResult = maskList.map((mask: ResultMaskType) =>
    getCalcualteResult(result.codes, mask, codePercentImportant, getBindCode)
  );

  const mergeCodeResult = maskResult[0].mergeCodeResult;
  const fullRatingValue = maskResult[0].fullRatingValue!;

  // const

  // console.log(mergeCodeResult, "mergeCodeResult");

  return (
    <div className="border border-gray-200 rounded-lg overflow-hidden p-4">
      <div className=" mx-2 flex justify-between items-center">
        <p className="text-md text-gray-500">
          {result.mode} ID: <span className="font-bold">{result._id}</span>
        </p>
        <button
          onClick={() => deleteItemHandler(result._id)}
          className="btn btn-square btn-sm btn-outline btn-error"
        >
          <Icon fontSize="26px" icon="mdi:trash-outline" />
        </button>
      </div>

      <div className="px-4 py-2">
        <AboutSection data={result} />
        {result.name && (
          <p className="text-accent text-lg font-bold">{result.name}</p>
        )}
        <div className=" my-2 text-error text-lg">
          {result.isFinished == false && "Тест не завершен!" }
        </div>
        <div className=" my-2">
          Общий рейтинг:{" "}
          <span className="text-accent font-medium">{fullRatingValue}</span>
        </div>
        <CodeSection codes={mergeCodeResult} />
      </div>
      <TableSection getBindCode={getBindCode} data={result} />
      <ResultCardMasks codes={result.codes} maskList={maskList} />
    </div>
  );
};

const AboutSection = ({ data }: { data: TestResultType }) => {
  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-4">
      <Field name="Пол" value={getSexName(data.user.sex)} />
      {data?.user?.status && (
        <Field name="Статус" value={getStatusName(data.user.status)} />
      )}
      {data?.user?.instagram && (
        <Field name="Instagram" value={data?.user?.instagram} />
      )}

      <Field name="Домен" value={data?.domain} />
      <Field name="Email" value={data?.user?.email} />

      {data?.user?.name && <Field name="Имя" value={data?.user?.name} />}
      {data?.user?.city && <Field name="Город" value={data?.user?.city} />}
      {data?.user?.country && (
        <Field name="Страна" value={data?.user?.country} />
      )}
      {data?.user?.phone && <Field name="Телефон" value={data?.user?.phone} />}
      <Field
        name="Дата создания"
        value={moment(data?.createdAt).format("HH:mm DD.MM.YYYY")}
      />
    </div>
  );
};

const CodeSection = ({ codes }: { codes: any }) => {
  return (
    <div className="mt-4 flex flex-col gap-2 items-start">
      {codes.map((item: any) => {
        return (
          <CodeItemInfo
            isImportant={item.isImportant}
            key={item.code}
            originalCode={item.code}
            percent={item.ratingPercent}
            statusName={item.statusName}
            statusColor={item.statusColor}
            bindCode={item.bindCode}
            data={item}
          />
        );
      })}
    </div>
  );
};

function CodeItemInfo({
  originalCode,
  percent,
  statusColor,
  statusName,
  bindCode,
  data,
  isImportant,
}: {
  originalCode: string;
  data: CodeItemType;
  percent: number;
  statusName: string;
  statusColor: string;
  bindCode: string;
  isImportant: boolean;
}) {
  let hideBindCode = false;
  if (originalCode == bindCode) {
    hideBindCode = true;
  }
  return (
    <div
      className={`text-sm gap-2 flex items-center rounded-lg px-2 py-1 border border-gray-200 ${
        isImportant ? "border-primary text-black" : ""
      }`}
    >
      {!hideBindCode && <div>{bindCode}</div>}

      <div className="font-medium">{`${percent}%`}</div>
      <div>
        {originalCode} - {data.value} ({data.count}-{data.positive}-
        {data.negative})
      </div>
      <div>
        {`K (${data.crucial}-${getFormatValue(
          data.positiveCrucial
        )}-${getFormatValue(data.negativeCrucial)})`}
      </div>

      <div className="p-1 px-2 rounded-md" style={{ background: statusColor }}>
        {statusName}
      </div>
    </div>
  );
}

const TableSection = ({
  getBindCode,
  data,
}: {
  getBindCode: (code: string) => string;
  data: TestResultType;
}) => {
  return (
    <div className="collapse collapse-arrow border border-gray-200 p-1 mt-2">
      <input type="checkbox" />
      <div className="collapse-title text-lg font-medium">Черты</div>

      <div className="collapse-content">
        <div className="overflow-x-auto">
          <table className="table">
            <thead>
              <tr>
                <th>Название</th>
                <th>Коэфициент</th>
                <th>Позитив</th>
                <th>Негатив</th>
                <th>Результат</th>
                <th>Код позитива</th>
                <th>Код негатива</th>
              </tr>
            </thead>
            <tbody>
              {data.traits.map((t: any) => {
                let exist =
                  isNumber(t.positiveValue) && isNumber(t.negativeValue);
                let isPositive = t.positiveValue >= t.negativeValue;

                return (
                  <tr>
                    <td>{t.name}</td>
                    <td className="text-success ">{t.coeff}</td>
                    <td className="text-success">{t.positiveValue}</td>
                    <td className="text-error">{t.negativeValue}</td>
                    {exist ? (
                      <td
                        className={isPositive ? "text-success" : "text-error"}
                      >
                        {isPositive ? t.value : -t.value}
                      </td>
                    ) : (
                      <td className="text-success">{t.value}</td>
                    )}

                    <td className="text-success">
                      {getBindCode(t.positiveModeCode)}
                    </td>
                    <td className="text-error">
                      {getBindCode(t.negativeModeCode)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Field = ({ name = "", value = "" }) => {
  return (
    <div>
      <p className="text-secondary-content text-xs">{name}</p>
      <p className="text-accent text-sm font-bold">{value}</p>
    </div>
  );
};

const getFormatValue = (val: any) => {
  if (typeof val == "number") {
    return val;
  } else {
    return "";
  }
};

function isNumber(val: number) {
  return !isNaN(val);
}

const getBackgroundStyle = (status: string, count: number) => {
  if (status) {
    switch (status) {
      case "lie":
        return "red";
      case "almostlie":
        return "orange";
      case "truth":
        return "purple";
      case "doubt":
        return "green";
      default:
        return "black";
    }
  }

  if (count >= 0) {
    if (count <= 2) {
      return "red";
    } else if (count <= 3) {
      return "orange";
    } else if (count > 3) {
      return "green";
    } else {
      return "black";
    }
  }
};

function getSexName(s: string) {
  switch (s) {
    case "male":
      return "Мужчина";
    case "female":
      return "Женщина";
  }
}

function getStatusName(s: string) {
  switch (s) {
    case "student":
      return "Студент";
    case "search-work":
      return "Ищет работу";
    case "work":
      return "Работает,но ищет новую работу";
  }
}

export default ResultCard;
