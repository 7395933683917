const domain = window.document.domain;
const isDevMode = process.env.NODE_ENV === "development";

let DOMAIN = "PROD";

if (!isDevMode) {
  // DOMAIN = domain.split(".")[0];
  DOMAIN = domain
    .replace(/^www\./, "") // Убираем "www."
    .split(".")[0]; // Берем первую часть до первой точки
}

// DOMAIN = "bitchscale";

export const getDomain = () => {
  return DOMAIN;
};
