import React, { useRef, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

// import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from "react-confetti";

const Finish = () => {
  // const divRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number>(0);

  // useEffect(() => {
  //   if (divRef.current) {
  //     setWidth(divRef.current.offsetWidth); // Получаем ширину элемента
  //   }
  // }, []);

  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col justify-center">
      {/* {width && <Confetti recycle={false} />} */}

      <div className="">
        <p className="text-3xl text-white text-center text-primary font-bold">
          {t("finish.title")}
        </p>
        <p className="text-xl text-white font-extralight mt-3 text-center">
          {t("finish.subtitle")}
        </p>
        {/* <p className="text-center mt-4 text-7xl">🥳</p> */}
      </div>
    </div>
  );
};

export default Finish;
