import React from "react";

import { CustomInput } from "components/UI/CustomInput";

import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";

import { useUser } from "context/userContext";
import axios from "axios";

console.log(2);
interface FormValues {
  country: string;
  city: string;
  isAgreement: boolean;
}

interface Props {
  finishHandler: Function;
}

const Location: React.FC<Props> = ({ finishHandler }) => {
  const initialValues: FormValues = {
    country: "",
    city: "",
    isAgreement: false,
  };

  const user = useUser();

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    country: Yup.string().required(t("field.requiredFieldError")),
    city: Yup.string().required(t("field.requiredFieldError")),
    isAgreement: Yup.boolean().isTrue(t("field.requiredFieldError")),
  });

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    actions.setSubmitting(false);
    user.updateUser("country", values.country);
    user.updateUser("city", values.city);    

    finishHandler();
  };

  return (
    <>
      <div className="h-full flex flex-col justify-center">
        <div>
          <p className="text-2xl text-white text-center mb-6 font-bold">
            {t("userLocation.title")}
          </p>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <CustomInput
                      name="country"
                      label={t("userLocation.inputCountryLabel")}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                      errorMessage={
                        touched.country && errors.country ? errors.country : ""
                      }
                    />
                    <CustomInput
                      name="city"
                      label={t("userLocation.inputCityLabel")}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      errorMessage={
                        touched.city && errors.city ? errors.city : ""
                      }
                    />
                  </div>
                  <div className="form-control">
                    <label className="label block cursor-pointer flex justify-start items-center">
                      <input
                        name="isAgreement"
                        type="checkbox"
                        checked={values.isAgreement}
                        onChange={handleChange}
                        className="checkbox border-white [--chkbg:#d605ba] [--chkfg:white] "
                      />
                      <span
                        className={`label-text ml-3 ${
                          touched.isAgreement && errors.isAgreement
                            ? "text-error"
                            : ""
                        }`}
                      >
                        {t("userLocation.agreementText")}
                      </span>
                    </label>
                  </div>
                  <div className="mt-5">
                    <button
                      type="submit"
                      className="btn btn-block btn-outline btn-primary w-full btn-large text-lg py-3 h-auto"
                    >
                      {t("button.next")}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;
